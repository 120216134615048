import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import List from '../../components/List'
import LayoutListView from '../../components/LayoutListView'

const news = ({ location }) => {
  const configID = '3i9cE7EQz5ZB5DTu05fcs9'
  const blogData = useStaticQuery(graphql`
    {
      allContentfulBlog(sort: { fields: createdAt, order: DESC }) {
        edges {
          node {
            id
            title
            slug
            image {
              file {
                url
              }
            }
            excerpt
            author
            authorProfileImage {
              file {
                url
              }
            }
            createdAt(formatString: "MMMM Do YYYY")
          }
        }
      }
    }
  `)

  return (
    <LayoutListView path={location.pathname} configID={configID}>
      <List
        data={blogData.allContentfulBlog.edges}
        itemType="blog"
        className="classic-blog classic-blog-center"
      />
    </LayoutListView>
  )
}

export default news
